.formfloat{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 99;
    
}
.formfloat-main{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floatform-card{
    background-color: #f1f1f1;
    position: relative;
    width: 30%;
    padding: 2rem;
    border-radius: 8px;
}

.formfloat-button{
    position: absolute;
    right: 2%;
    top: 2%;
}
.formfloat-button svg{
    margin: 0;
    background-color: #e8b86d;
    border-radius: 8px;
   font-size: 25px;
   color: white;
   cursor: pointer;
}

@media only screen and (max-width: 540px) {
    .floatform-card{
        position: relative;
        width: 100%;
    }
}