.Amenities {
  padding: 3rem 0rem;
  width: 100%;
  background-color: #fafafa;
}

.Amenities-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.Amenities-main h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.Amenities-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid #f8e8c2;
}

.Amenities-card {
  /* background-color: gray; */
  padding: 3rem 2rem;
  /* border: 1px solid gray; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Amenities-card p svg{
  font-size: 60px;
}

@media (min-width: 1200px) {
  .Amenities-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 990px) {
  .Amenities-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid #e9eff2;
  }

}
