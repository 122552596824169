.Footer {
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(145deg, rgba(255, 255, 255, 1) 0%, #ba994a 100%);
}

.Footer-main,
.footer-down {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footer-item h5 {
  display: flex;
  align-items: center;
  color: black;
  text-align: center;
  padding: 14px 0px;
  width: 55%;
  margin-bottom: 0;
}

.footer-item h2 {
  font-size: 20px;
}
.footer-item h2:after {
  content: "";
  margin-top: 5px;
  display: flex;
  height: 2px;
  width: 25%;
  border-radius: 50px;
  background-color: #b3b992;
}

.footer-box {
  padding-top: 5rem;
  display: grid;
  grid-template-columns: 30% 30% 40%;
  gap: 1rem;
  /* align-items: center; */
}

.footer-box img {
  width: 70%;
  margin-bottom: 2rem;
}

.footer-item ul {
  text-align: center;
  padding: 0;
}

.footer-item p {
  color: black;
}
.footer-item ul li {
  display: flex;
  align-items: center;
}
.footer-item ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 16px;
}

.footer-item ul li a:hover {
  color: #c5705d;
}
.footer-item ul li a svg {
  margin-right: 12px;
  font-size: 25px;
}

.footer-copyright {
  width: 100%;
  border-top: 1px solid rgb(99, 99, 99);
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-copyright p a {
  color: black;
  text-decoration: none;
}

.footer-copyright p {
  font-size: 14px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  color: black;
  text-decoration: none;
  margin: 0;
}

.footer-item-disclaimer p {
  font-size: 14px;
  display: flex;
  justify-content: center;
  color: rgb(43, 43, 43);
  text-decoration: none;
  margin: 0;
}
.footer-item-disclaimer .disclaimer {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.footer-item-policy a {
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.footer-contact {
  display: flex;
  align-items: baseline;
}
.footer-contact p svg {
  font-size: 22px;
  margin-right: 10px;
}
.footer-contact a {
  color: black;
  text-decoration: none;
  margin-right: 10px;
}
.footer-contact a svg {
  font-size: 22px;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .Footer-main,
  .footer-down {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
  .footer-contact {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .footer-item ul li {
    justify-content: center;
  }

  .footer-item ul li a {
    margin: 0 8px;
    font-size: 15px;
  }
  .footer-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .footer-box {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer-box img {
    width: 40%;
    margin-bottom: 2rem;
  }

  .footer-item p {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  .footer-item:nth-child(3) ul li a {
    font-size: 30px;
    font-weight: 700;
  }
  .footer-item ul li a svg {
    font-size: 30px;
    margin-right: 15px;
    font-weight: 700;
  }


  .footer-copyright {
    flex-direction: column;
    justify-content: center;
}

  .footer-copyright p {
    padding: 10px 0px;
    font-size: 12px;
    text-align: center;
  }
  .footer-item h5 {
    width: 48%;
  }
  .footer-item h2:after {
    width: 100%;
  }
}
