.Walkthrough {
  padding: 3rem 0rem;
  width: 100%;
  background-color: #ba994a;
}

.Walkthrough-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.Walkthrough-main h2 {
  font-size: 30px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.walkthrough-item {
  display: flex;
  gap: 1rem;
}

.walktrough-card:first-child {
  grid-area: box1;
}
.walktrough-card:nth-child(2) {
  grid-area: box2;
}

.walktrough-card:nth-child(3) {
  grid-area: box3;
}
.walktrough-card:nth-child(4) {
  grid-area: box4;
}
.walkthrough-left {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "box1 box1 box2"
    "box3 box4 box4";
    gap: 10px;
  width: 70%;
  position: relative;
}

.walktrough-card img {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 3/1;
  height: 100%;
}

.walkthrough-left iframe {
  width: 100%;
  height: 100%;
}
.walkthrough-right {
  width: 30%;
}

.walkthrough-form {
  padding: 2rem;
  border-radius: 8px;
  background-color: #fafafa;
}
.walkthrough-form h4 {
  font-size: 18px;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .Walkthrough-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
  .walkthrough-item {
    flex-wrap: wrap;
  }

  .walkthrough-left,
  .walkthrough-right {
    width: 100%;
  }

  .category-card {
    height: 200px;
  }

  .walkthrough-left {
    height: 25vh;
  }
}
