.Highlights {
  width: 100%;
  position: relative;
  padding: 6rem 0;
}

.highlights-item {
  display: flex;
  gap: 2rem;
}

.highlights-left {
  position: relative;
  z-index: 2;
  width: 50%;
  right: 25%;
  padding: 2rem 3rem ;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #ba994a;
  border-radius: 8px;
}
.highlights-left h2{
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  color: white;
}
.highlights-left ul li{
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  color: white;
}
.highlights-left ul{
  padding: 0;
}
.highlights-left ul li svg{
  margin-right: 10px;
  color: #eee238;
  font-size: 20px;
}


.highlights-right {
  width: 50%;
}
.highlights-right img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  filter: brightness(70%);
}

@media (min-width: 1200px) {
}

@media only screen and (max-width: 680px) {
  .highlights-item {
    flex-wrap: wrap;
  }
  .highlights-left {
    width: 100%;
    padding: 3rem 1rem 1rem 1rem;
    right: 0;
  }
  .highlights-right {
    width: 100%;
  }
}
