.aboutUs {
  padding: 1rem 0 3rem 0rem;
  width: 100%;
  background-color: #fafafa;
  position: relative;
}

.about-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.about-items {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.about-left {
  width: 60%;
}

.about-title {
  text-align: start;
  margin-bottom: 1rem;
}

.about-detail h3 {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  color: black;
  padding-bottom: 20px;
}
.about-detail p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: black;
  padding-bottom: 20px;
}

.about-right {
  width: 40%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.about-right img {
  object-fit: cover;
  /* border-radius: 10px; */
  width: 100%;
  height: 100%;
  /* rotate: 90deg; */
}

.about-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.about-button a {
  text-decoration: none;
  color: white;
  font-size: 14px;
}

.about-button a {
  padding: 10px 24px;
  background: #ba994a;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 25px;
}

.about-button a p {
  margin: 0;
  font-size: 20px;
}

@media (min-width: 1200px) {
  .about-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 990px) {
  .about-main {
    max-width: 720px;
  }
}
@media only screen and (max-width: 680px) {
  .about-items {
    flex-wrap: wrap;
  }

  .about-left,
  .about-right {
    width: 100%;
  }

  .about-title h4 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;
  }

  .about-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .about-main {
    max-width: 520px;
  }

  .about-button a {
    width: 100%;
    text-align: center;
  }
}
