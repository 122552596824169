.banner img {
  height: 90vh;
  width: 100%;
  object-fit: cover;
  filter: brightness(70%);
}

.banner-image:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  left: 0;
  display: inline-block;
  opacity: 0.9;
  background-image: linear-gradient(0deg, #000 0%, rgba(255, 255, 255, 0) 100%);
}
.banner {
  margin-top: 75px;
  position: relative;
}

.stickey img {
  width: 100%;
  object-fit: cover;
}

.banner-stick {
  position: absolute;
  top: 30%;
  left: 5%;
  z-index: 1;
}

.banner-stick-box {
  /* background-color: white; */
  color: white;
  padding: 2rem 3rem;
  border-radius: 8px 8px 0 0;
  /* text-align: center; */
}

.banner-stick-box h2 {
  /* margin-bottom: 1rem; */
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ba994a;
}
.banner-stick-box span {
  font-size: 30px;
  font-weight: 500;
}
.banner-stick-box h5 {
  margin: 10px 0px;
  font-weight: 300;
  font-size: 18px;
  padding: 12px 24px;
  background-color:#ffffff54;
  border-radius: 25px;
}
.banner-stick-box p {
  margin-top: 2rem;
}
.banner-stick-box p a {
  text-decoration: none;
  color: white;
  background-color: #ba994a;
  padding: 12px 24px;
  border-radius: 25px;
  margin: 0;
  cursor: pointer;
}
.banner-stick-box p svg {
  margin-left: 10px;
}

@media only screen and (max-width: 680px) {
  .banner img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 680px) {
  .banner-stick {
    background-color: black;
    position: relative;
    left: 0;
    z-index: 1;
  }

  .banner-stick-box {
    padding: 1rem;
  }
  .banner-stick-box h2 {
    font-size: 35px;
  }
  .banner-stick-box span {
    font-size: 25px;
  }
  .banner-stick-box h5 {
    background-color:#202020;
  }
}
