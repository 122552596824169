.form button {
  width: 100%;
  background-color: #ba994a;
  border: none;
  transition: .3s ease-in-out;
  padding: 15px 0px;
  border-radius: 25px;
}
.form button:hover {
  background-color: #ba994a;
  color: black;
}
.form .form-control {
  font-size: 13px;
}
.form textarea {
  min-height: 120px;
  resize: vertical;
}

