.PriceList {
  padding: 3rem 0;
  width: 100%;
  margin-top: 80px;
}


@media only screen and (max-width: 680px) {
  .pricelist-table {
    overflow: auto;
  }
}
