.overview {
  padding: 3rem 0rem;
  width: 100%;
  background-color: #ba994a;
}

.about-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.overview-item {
  display: flex;
  gap: 2rem;
}

.overview-left {
  width: 30%;
  height: 100%;
}

.overview-left .overview-form {
  padding: 2rem;
  border-radius: 8px;
  background-color: #fafafa;
}
.overview-left .overview-form h4{
  font-size: 17px;
  margin-bottom: 25px;
}

.overview-right {
  width: 70%;
}

.overview-title h3 {
  color: white;
  font-size: 30px;
  line-height: 35px;
  padding-bottom: 23px;
  font-weight: bold;
}

.overview-detail p {
  margin: 0 0 0px 0;
  font-weight: 400;
  font-size: 16px;
  color: #fafafa;
  padding-bottom: 15px;
}


.overview-category img {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  object-fit: cover;
  height: 100% !important;
}

.category-card {
  position: relative;
  margin: 10px;
  height: 150px;
}
.category-card:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  left: 0;
  display: inline-block;
  border-radius: 5px;
  opacity: 0.9;
  background-image: linear-gradient(0deg, #000 0%, rgba(255, 255, 255, 0) 100%);
}
.overview-category p {
  position: absolute;
  bottom: 4%;
  color: white;
  margin: 0;
  left: 5%;
  z-index: 2;
}

.overview-category{
  margin-top: 20px;
}

.overview-category .owl-nav button {
    background-color: white !important;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 25px !important;
  }
  .overview-category .owl-nav button.owl-prev {
    position: absolute;
    top: 40%;
    left: -1%;
  }
  .overview-category .owl-nav button.owl-next {
    position: absolute;
    top: 40%;
    right: -1%;
  }
@media (min-width: 1200px) {
  .about-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 990px) {
  .about-main {
    max-width: 720px;
  }
}
@media only screen and (max-width: 680px) {
  .overview-item {
    flex-wrap: wrap;
  }

  .overview-left,
  .overview-right {
    width: 100%;
  }

  .category-card {
    height: 200px;
  }
}
